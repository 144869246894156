window.fbAsyncInit = function() {
  FB.init({
    appId: '1019983541441613',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v2.10',
  });
  FB.AppEvents.logPageView();
  FB.getLoginStatus(function(response) {
    // if connected and there is no user token, log the user in and/or create an account for said user
    const token = shellObj.getCookie('userToken');
    if (typeof token === 'undefined' || token === null || token === '') {
      document.getElementById('account_options_container').style.display='none';
    } else {
      // did they login with Facebook? if so, let's grab their data!
      const facebookLink = shellObj.getCookie('facebookLinkID');
      if (typeof facebookLink === 'undefined' || facebookLink === null || facebookLink === '') {
        // no linking to Facebook
      } else {
        // linked to facebook... if status is connected:
        if (response.status === 'connected' && facebookLink === response.authResponse.userID) {
          const profilePic = sessionStorage.getItem('profilePic');
        } else {
          FB.logout();
        }
      }
    }
  });
};

/**
@param {function} cback
*/
function logOutOfFB(cback = () => undefined) {
  const facebookLink = shellObj.getCookie('facebookLinkID');
  FB.getLoginStatus(async function(response) {
    if (response.status === 'connected') {
      await FB.logout();
    }
    cback();
  });
}
window.logOutOfFB = logOutOfFB;

window.addEventListener('load', function load(event) {
  const fbroot = document.createElement('div'); // <div id="fb-root"></div>
  fbroot.id = 'fb-root';
  document.body.appendChild(fbroot);
  (function(doc, scriptTag, id) {
    const fjs = doc.getElementsByTagName(scriptTag)[0];
    if (doc.getElementById(id)) return;
    const jsTag = doc.createElement(scriptTag);
    jsTag.id = id;
    jsTag.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.10&appId=1019983541441613';
    jsTag.setAttribute('async', '');
    jsTag.setAttribute('defer', '');
    fjs.parentNode.insertBefore(jsTag, fjs);
  }(document, 'script', 'facebook-jssdk'));
});
